import { axiosInstance } from "@/plugins/axios";

const SyncClients = () => {
  const baseUrl = "/api/ClientAccount";

  const GetAllClientDifferences = async (): Promise<any[]> => {
    const res = await axiosInstance.get(baseUrl + '/GetAllClientDifferences');
    return Promise.resolve(res.data);
  };

  const SyncClient = async (): Promise<void> => {
    return Promise.resolve();
  }

  return {
    GetAllClientDifferences,
    SyncClient
  };
};
export default SyncClients;
