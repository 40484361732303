import { axiosInstance } from "@/plugins/axios";
import { SafexDivision } from "models";

const SafexDivision = () => {
  const baseUrl = "/api/SafexDivision";

  const getAllDivisions = async (): Promise<SafexDivision[]> => {
    const res = await axiosInstance.get(baseUrl + `/getAllDivsions`);
    return Promise.resolve(res.data);
  };

  const RemoveSafexCode = async (safexCode: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/RemoveSafexCode?safexCode=${safexCode}`
    );
    return Promise.resolve(res.data);
  };

  const ReActivateSafexCode = async (safexCode: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/ReActivateSafexCode?safexCode=${safexCode}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllDivisions,
    RemoveSafexCode,
    ReActivateSafexCode,
  };
};
export default SafexDivision;
