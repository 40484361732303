
import { Component, Vue, Watch } from "vue-property-decorator";
import AddEditMarket from "@/components/Manage/Markets/AddEditMarket.vue";
import { Market, UserAccessModel } from "models";
import Hooks from "@/hooks";
import User from "@/store/modules/user";

const { SyncClients } = Hooks;
const marketHook = SyncClients();

@Component({
  components: {
    AddEditMarket,
  },
})
export default class SyncClientsView extends Vue {
  items: {
    [key: string]: any;
  } = {};
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "";
  refresh = false;
  headers = [
    { text: "Name", value: "marketName" },
    { text: "Code", value: "marketCode" },
    { text: "", value: "actions", sortable: false, align: "end" },
  ];

  async syncTrader(): Promise<void> {
    try {
      const res = await marketHook.SyncClient();
      this.$notificationCreator.createSuccessNotification("Trader Synced");
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.init();
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  get transformedItems(): any[] {
    return Object.keys(this.items).map((key) => ({
      id: key,
      clientFileDetails: this.items[key].clientFileDetails,
      jseClientFileDetails: this.items[key].jseClientFileDetails,
    }));
  }
  async init(): Promise<void> {
    try {
      this.loading = true;
      const res = await marketHook.GetAllClientDifferences();
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
